
import { auditTags, courseSeriesTags, courseTypeTags } from '@/states/tags'
import { computed, ref } from 'vue'

export default {
  setup: () => {
    const activeTag = ref(1)
    const keyword = ref('')

    const selecteTags = computed(() =>  {
      switch(activeTag.value){
        case 1:
          return courseSeriesTags.value
          break;
        case 2:
          return courseTypeTags.value
          break;
        default:
          return auditTags.value
      }
    })

    const tagsAfterMatchKeyword = computed(() => {
      const k = keyword.value
      const list = selecteTags.value
      if (!k) {
        return list
      }
      return list.filter(t => t.tagName.includes(k))
    })

    return {
      tagsAfterMatchKeyword,
      activeTag,
      keyword,
      auditTags,
      courseSeriesTags
    }
  }
}
